/* /MyCMSapp/frontend/src/components/Header.module.css */

* {
  transition-property: all; /* Applies transition effects to all properties */
  transition-duration: .2s; /* Sets the duration of the transition to 0.2 seconds */
}

.header {
  background-color: #f8f8f8; /* Sets the background color */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Adds a shadow effect */
}

.headerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
}
.welcomeMessage {
  font-size: 14px;
  color: #333;
}
.mainMenu {
  display: flex; /* Uses flexbox layout */
  justify-content: space-between; /* Distributes space between items */
  align-items: center; /* Aligns items vertically in the center */
  padding: 1px 10px; /* Adds padding: 10px top/bottom, 20px left/right */
}

.logo {
  font-size: 1.5em; /* Sets the font size */
  color: #160f34; /* Sets the text color */
  text-decoration: none; /* Removes underline from text */
  font-weight: bold; /* Makes the font bold */
  margin-right: 5px; /* Adds margin to the right */
  font-family: var(--font-chartere);

}

.header h2 {
  position: absolute;
  color: rgb(8, 8, 50); /* Sets the text color */
  background: #f8f8f8; /* Sets the background color */
  padding: 8px 20px; /* Adds padding: 10px top/bottom, 20px left/right */
  font-size: 12px; /* Sets the font size */
  text-decoration: none; /* Removes underline from text */
  letter-spacing: 2px; /* Adds space between letters */
  text-transform: uppercase; /* Transforms text to uppercase */
  left: 0; /* Adds margin to the left */
  float: left;


}

h3 {
  
  color: rgb(8, 8, 50); /* Sets the text color */
  background: #f8f8f8; /* Sets the background color */
 
  font-size: 12px; /* Sets the font size */
  text-decoration: none; /* Removes underline from text */
  letter-spacing: 2px; /* Adds space between letters */
  text-transform: uppercase; /* Transforms text to uppercase */
  left: 0; /* Adds margin to the left */
  float: left;

}
.logoImage {
  width: 50px; /* Sets the width of the image */
  height: 50px; /* Sets the height of the image */
  /*  background-image: url('../../public/images/logoGY.jpg'); /* Sets the background image */
  background-size: contain; /* Scales the image to fit the container */
  background-repeat: no-repeat; /* Prevents the background image from repeating */
  border-radius: 50%; /* Makes the image round */
}

.nav {
  display: flex; /* Uses flexbox layout */
}

.navList {
  list-style: none; /* Removes bullets from list items */
  display: flex; /* Uses flexbox layout */
  gap: 5px; /* Adds space between items */
  padding: 0; /* Removes default padding */
  margin: 0; /* Removes default margin */
  
}

.navItem {
  display: flex; /* Uses flexbox layout */
    float: right;

}

.navItem h2 {
  color: #160f34;
}

.navLink,
.navButton {
  display: block; /* Displays as block element */
  color: rgb(8, 8, 50); /* Sets the text color */
  background: #f8f8f8; /* Sets the background color */
  padding: 10px 20px; /* Adds padding: 10px top/bottom, 20px left/right */
  font-size: 12px; /* Sets the font size */
  text-decoration: none; /* Removes underline from text */
  letter-spacing: 2px; /* Adds space between letters */
  text-transform: uppercase; /* Transforms text to uppercase */
  border-radius: 4px; /* Rounds the corners */
  transition: background-color 0.3s, color 0.3s, padding 0.3s; /* Adds transition effects for background color, text color, and padding */
  margin-left: 5px; /* Adds margin to the left */
  font-family: var(--font-libre);

}

.navLink:hover,
.navButton:hover,
.navLink.active {
  background: rgb(66, 33, 134); /* Sets the background color on hover/active */
  color: #ffffff; /* Sets the text color on hover/active */
  padding: 10px 20px; /* Adds padding: 10px top/bottom, 20px left/right */
}

.quickActions {
  display: flex; /* Uses flexbox layout */
  justify-content: flex-end; /* Aligns items to the end of the container */
  gap: 5px; /* Adds space between items */
  margin-top: 1px; /* Adds margin to the top */
  padding: px 20px; /* Adds padding: 10px top/bottom, 20px left/right */
}

.quickActionLink {
  display: block; /* Displays as block element */
  color: rgb(8, 8, 50); /* Sets the text color */
  background: #f8f8f8; /* Sets the background color */
  padding: 5px 20px; /* Adds padding: 10px top/bottom, 20px left/right */
  font-size: 12px; /* Sets the font size */
  text-decoration: none; /* Removes underline from text */
  letter-spacing: 2px; /* Adds space between letters */
  text-transform: uppercase; /* Transforms text to uppercase */
  border-radius: 4px; /* Rounds the corners */
  transition: background-color 0.3s, color 0.3s, padding 0.3s; /* Adds transition effects for background color, text color, and padding */
}

.quickActionLink:hover,
.quickActionLink.active {
  background: rgb(165, 2, 100); /* Sets the background color on hover/active */
  color: #ffffff; /* Sets the text color on hover/active */
  padding: 5px 20px; /* Adds padding: 10px top/bottom, 20px left/right */
}

body {
  background: #f8f8f8; /* Sets the background color */
  padding: 5px; /* Adds padding */
  text-align: left; /* Aligns text to the left */
  font-family: 'Oswald', sans-serif; /* Sets the font family */
}

h1 {
  color: #2c2c2c; /* Sets the text color */
  font-weight: bold;
   /* Sets the font weight */
}

.footer {
  font-size: 8px; /* Sets the font size */
  color: #fff; /* Sets the text color */
  clear: both; /* Ensures the footer clears previous floating elements */
  display: block; /* Displays as block element */
  letter-spacing: 5px; /* Adds space between letters */
  border: 1px solid #fff; /* Adds a border */
  padding: 5px; /* Adds padding */
  text-decoration: none; /* Removes underline from text */
  width: 210px; /* Sets the width */
  margin: auto; /* Centers the footer */
  margin-top: 400px; /* Adds margin to the top */
}





.navToggle {
  display: none;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #333; /* Dark grey color for better visibility */
}


@media screen and (max-width: 768px) {
  .navToggle {
    display: block;
  }

  .nav {
    display: none;
    position: fixed;
    top: 0;
    left: -100%;
    width: 35%;
    height: 37vh;
    background-color: #f8f9fa;
    z-index: 1000;
    transition: left 0.3s ease-in-out;
  }
  .welcomeMessage {
    margin-bottom: 10px;
  }

  .navVisible {
    display: block;
    left: 0;
  }

  .navList {
    flex-direction: column;
    padding: 1rem;
    height: 100%;
    overflow-y: auto;
  }

  .navItem {
    margin: 0.5rem 0;
  }

  .navLink,
  .navButton {
    display: block;
    width: 100%;
    text-align: left;
    padding: 0.5rem 1rem;
  }
}