.create-user-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
}

.success-message {
    color: green;
    font-weight: bold;
}

.error-message {
    color: red;
    font-weight: bold;
}

form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

input, select, button {
    padding: 10px;
    font-size: 16px;
}

button {
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
}

button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}