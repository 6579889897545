.list-blogs-container {
    padding: 20px;
}

.create-blog-btn {
    margin-bottom: 20px;
}

.blog-table {
    width: 100%;
    border-collapse: collapse;
}

.blog-table th, .blog-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.blog-table th {
    background-color: #f2f2f2;
    cursor: pointer;
    user-select: none;
}

.blog-table th:hover {
    background-color: #ddd;
}

.order-btn {
    margin: 0 5px;
}

.order-value {
    display: inline-block;
    min-width: 20px;
    text-align: center;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
}

input:checked + .slider {
    background-color: #2196F3;
}

input:checked + .slider:before {
    transform: translateX(26px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.edit-btn, .delete-btn {
    margin-right: 5px;
}