/* LoginPage.css */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  background: linear-gradient(135deg, #8acefb, #47048b);
}


.content-page h2 {
  text-align: center;
  margin-bottom: 50px;
  box-shadow: 0 3px 7px -1px rgba(0, 0, 0, 0.1);
  background: #fff;
  display: block;
  color: rgb(8, 8, 50);
  background: #f8f8f8;
  padding: 10px 10px;
  font-size: 14px;
  font-weight: Bold;
  letter-spacing: 4px;
  text-transform: uppercase;
  border-radius: 4px;

}
.login-form {
  background: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.login-title {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  color: #333;
  text-align: center;
  font-family: var(--font-chartere);

}


.error-message {
  color: #e74c3c;
  margin-bottom: 1rem;
  text-align: center;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #333;
}

.form-input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: var(--font-libre);

}

.login-button {
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  background: #3498db;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s;
}

.login-button:hover {
  background: #2980b9;
}
