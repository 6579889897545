/* File: frontend/src/components/blogs/BlogMainPage.css */

.blog-main-container {
    max-width: 1200px;  /* Increased for two columns */
    margin: 20px auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.blog-main-content {
    line-height: 1.6;
    font-family: Arial, sans-serif;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
    width: 100%;  /* Changed from 80% to 100% */
}

h1 {
    text-align: center;
    margin-bottom: 40px;
    box-shadow: 0 3px 7px -1px rgba(0, 0, 0, 0.1);
    background: #fff;
    display: block;
    color: rgb(8, 8, 50);
    background: #f8f8f8;
    padding: 10px 10px;
    font-size: 14px;
    font-weight: Bold;
    letter-spacing: 4px;
    text-transform: uppercase;
    border-radius: 4px;
    font-family: 'Oswald', sans-serif;
}

h2 {
    text-align: center;
    margin-bottom: 40px;
    box-shadow: 0 3px 7px -1px rgba(0, 0, 0, 0.1);
    background: #fff;
    display: block;
    color: rgb(8, 8, 50);
    background: #f8f8f8;
    padding: 10px 10px;
    font-size: 12px;
    font-weight: Bold;
    letter-spacing: 4px;
    text-transform: uppercase;
    border-radius: 4px;
}

.blog-main-content p {
    margin-bottom: 1em;
}

.blog-main-content img {
    max-width: 100%;
    height: auto;
}

/* Two-column layout styles */
.two-column-layout {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.column {
    width: 48%;  /* Adjust as needed */
}

.blog-summary {
    border-bottom: 1px solid #ccc;
    padding: 5px 0;
    font-family: 'Chartere ,Times New Roman', Times, serif;
    margin: 20px 0;  /* Changed from margin: 20px; */
}

.blog-summary h2 {
    margin: 0 0 10px 0;
}

.blog-summary a {
    color: #007bff;
    text-decoration: none;
}

.blog-summary a:hover {
    text-decoration: underline;
}

.blog-image {
    max-width: 100%;
    height: auto;
    display: block;
    margin-bottom: 10px;
}

.blog-preview-image {
    width: 100%;
    height: 229px;
    border-radius: 2cap;
    overflow: hidden;
    object-fit: cover;  /* This ensures the image covers the area without distortion */
}

.blog-created-date {
    font-size: 14px;
    color: #666;
    margin-bottom: 10px;
    font-family: Arial, Helvetica, sans-serif;
}

/* Responsive design */
@media (max-width: 768px) {
    .two-column-layout {
        flex-direction: column;
    }

    .column {
        width: 100%;
    }

    .blog-main-container {
        padding: 10px;
    }

    .blog-preview-image {
        height: auto;  /* Allow height to adjust based on content */
    }
}