/* File: frontend/src/components/users/ListUsers.css */

.users-list-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}

h1 {
    color: #333;
    margin-bottom: 20px;
}

.btn-primary {
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    text-decoration: none;
    border-radius: 5px;
    display: inline-block;
    margin-bottom: 20px;
}

.users-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.users-table th,
.users-table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
}

.users-table th {
    background-color: #f2f2f2;
    color: #333;
    font-weight: bold;
}

.users-table tr:nth-child(even) {
    background-color: #f8f8f8;
}

.users-table tr:hover {
    background-color: #e8e8e8;
}

.action-buttons {
    display: flex;
    gap: 10px;
}

.action-button {
    padding: 5px 10px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    text-decoration: none;
    font-size: 14px;
}

.lock-button {
    background-color: #ffc107;
    color: #000;
}

.edit-button {
    background-color: #17a2b8;
    color: white;
}

.change-password-button {
    background-color: #28a745;
    color: white;
}

.delete-button {
    background-color: #dc3545;
    color: white;
}