/* File: frontend/src/components/Dashboard.module.css */

.dashboardHeader h1 {
    text-align: center;
    margin-bottom: 40px;
    box-shadow: 0 3px 7px -1px rgba(0, 0, 0, 0.1);
    background: #fff;
    display: block;
    color: rgb(8, 8, 50);
    background: #f8f8f8;
    padding: 10px 10px;
    font-size: 14px;
    font-weight: Bold;
    letter-spacing: 4px;
    text-transform: uppercase;
    border-radius: 4px;
}

.dashboardHeader h2 {
    text-align: center;
    margin-bottom: 40px;
    box-shadow: 0 3px 7px -1px rgba(0, 0, 0, 0.1);
    background: #fff;
    display: block;
    color: rgb(8, 8, 50);
    background: #f8f8f8;
    padding: 10px 10px;
    font-size: 12px;
    font-weight: Bold;
    letter-spacing: 4px;
    text-transform: uppercase;
    border-radius: 4px;
}

.nav {
    display: flex;
}

.navList {
   
    gap: 5px;
    padding: 0;
    margin-left: 20%;
}

.navItem {
    display: flex;
}

.navItem h2 {
    color: #160f34;
}

.navLink,
.navButton {
    display: block;
    color: rgb(8, 8, 50);
    background: #f8f8f8;
    padding: 10px 20px;
    font-size: 12px;
    text-decoration: none;
    letter-spacing: 2px;
    text-transform: uppercase;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s, padding 0.3s;
    margin-left: 5px;
    font-family: var(--font-libre);
}

.navLink:hover,
.navButton:hover,
.navLink.active {
    background: rgb(94, 47, 109);
    color: #ffffff;
    padding: 10px 20px;
}

.twoColumnLayout {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.column {
    width: 48%;
}