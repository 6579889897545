/* Add the following styles for the toggle switch and edit button */
.switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:checked + .slider:before {
    transform: translateX(14px);
  }
  
  .edit-btn {
    display: inline-block;
    padding: 5px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 4px;
  }
  
  .edit-btn:hover {
    background-color: #0056b3;
  }
  
  .btn-edit, .btn-delete {
    padding: 5px;
    margin-right: 5px;
    text-decoration: none;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 3px;
}

.btn-delete {
    background-color: #dc3545;
}
