/* Folder: frontend/src/components/common */
/* File: Editor.css */



.content-page {
    background-color: white;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin: 10px auto;
    width: 80%;
  }


.create-blog-container,
.view-blog-container,
.edit-blog-container,
.editor-container,
.create-page-container,
.view-page-container,
.edit-page-container {
    max-width: 800px;
    margin: 1px auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    
}

 h1 {
    margin-top: 0px;
    text-align: center;
    margin-bottom: 10px;
    box-shadow: 0 3px 7px -1px rgba(0, 0, 0, 0.1);
    background: #fff;
    display: block;
    color: rgb(8, 8, 50);
    background: #f8f8f8;
    padding: 10px 10px;
    font-size: 14px;
    font-weight: Bold;
    letter-spacing: 4px;
    text-transform: uppercase;
    border-radius: 4px;
}

.title-input {
    width: calc(100% - 20px);
    margin: 0 0 20px;
    font-size: 1.2rem;
    border: 1px solid #ccc;
    border-radius: 8px;
}

.toolbar {
    background-color: #333;
    border-bottom: 1px solid #ccc;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    border-radius: 8px;
}

.toolbar button,
.toolbar input[type="color"],
.toolbar input[type="file"] {
    background: #555;
    border: 1px solid #444;
    padding: 5px 10px;
    color: white;
    cursor: pointer;
    border-radius: 4px;
}

.toolbar button:hover,
.toolbar input[type="color"]:hover,
.toolbar input[type="file"]:hover {
    background: #ce01af;
}

#editor {
    padding: 5px;
    min-height: 400px;
    border: 1px solid #ccc;
    margin-top: 10px;
    margin-bottom: 5px;
    border-radius: 8px;
    padding-bottom: 20px;
    background-color: #fff;
    overflow: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
    line-height: normal; /* Default line height */
    font-family: var(--font-libre);

}

#editor[style*="line-height: 1.5"] {
    line-height: 1.5 !important;
}

#editor[style*="line-height: 2"] {
    line-height: 2 !important;
}

#editor[style*="line-height: 2.5"] {
    line-height: 2.5 !important;
}

#editor p {
    margin-bottom: 1em;
}



/* Image wrapper styles */
.img-wrapper {
    position: relative;
    display: inline-block;
    margin: 10px 0;
    border-radius: 2cap;

}

.img-wrapper img {
    max-width: 100%;
    height: auto;
    display: block;
    border-radius: 2cap;
    padding: 5px;

}

/* Image sizes */
.img-wrapper.size-small {
    width: 33%;
}

.img-wrapper.size-medium {
    width: 50%;
}

.img-wrapper.size-large {
    width: 100%;
}

/* Image placements */
.img-wrapper.left {
    float: left;
    margin-right: 15px;
    margin-bottom: 10px;
    border-radius: 2cap;
}

.img-wrapper.right {
    float: right;
    margin-left: 15px;
    margin-bottom: 10px;
    border-radius: 2cap;
}

.img-wrapper.center {
    margin-left: auto;
    margin-right: auto;
    border-radius: 2cap;
}

.img-wrapper.banner {
    width: 100%;
    height: 229px;
    overflow: hidden;
    border-radius: 2cap;
}

.img-wrapper.banner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 2cap;
}

/* Resizers */
.resizer {
    width: 10px;
    height: 10px;
    background: #000;
    border: 2px solid #fff;
    position: absolute;
    z-index: 10;
    border-radius: 50%;
    display: none;
}

.resizer.top-left { left: -5px; top: -5px; cursor: nw-resize; }
.resizer.top-right { right: -5px; top: -5px; cursor: ne-resize; }
.resizer.bottom-left { left: -5px; bottom: -5px; cursor: sw-resize; }
.resizer.bottom-right { right: -5px; bottom: -5px; cursor: se-resize; }
.resizer.top { top: -5px; left: 50%; cursor: n-resize; transform: translateX(-50%); }
.resizer.bottom { bottom: -5px; left: 50%; cursor: s-resize; transform: translateX(-50%); }
.resizer.left { left: -5px; top: 50%; cursor: w-resize; transform: translateY(-50%); }
.resizer.right { right: -5px; top: 50%; cursor: e-resize; transform: translateY(-50%); }

.img-wrapper.active .resizer {
    display: block;
}

/* Image menu */
.image-menu {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    z-index: 1000;
}

.image-menu select {
    margin-bottom: 10px;
    width: 100%;
}

.image-menu button {
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 5px 10px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
}

.image-menu button:hover {
    background-color: #e0e0e0;
}

/* Table menu */
.table-menu {
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    margin-top: 10px;
}

.table-menu label {
    display: block;
    margin-bottom: 5px;
}

.table-menu input {
    width: 50px;
    margin-bottom: 10px;
}

.table-menu button {
    padding: 5px 10px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
}

.table-menu button:hover {
    background-color: #e0e0e0;
}

/* Dropdown styles */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

.dropdown-content button {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    background: none;
    border: none;
    width: 100%;
    text-align: left;
}

.dropdown-content button:hover {
    background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
    display: block;
}

/* Clear floats after image containers */
#editor::after {
    content: "";
    clear: both;
    display: table;
}

.view-page-content {
    line-height: 1.6;
  
}

.view-page-content p {
    margin-bottom: 1em;
}

.view-page-content img {
    max-width: 100%;
    height: auto;

}

.image-resize-controls {
    display: flex;
    gap: 5px;
    align-items: center;
    padding: 4px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #363636;
}

.resize-input {
    width: 40px;
    height: 12px;
    padding: 5px;
    border: 1px solid #535353;
    border-radius: 3px;
    font-size: 12px;
    background-color: #a2a2a2;
}

.resize-input::placeholder {
    color: #4d4d4d;
    font-size: 12px;
    background-color: #e2dcdc;

}

.toolbar .save-button {
    background-color: #7034fb;
    margin-right: 10px;
    color: #fff;
    border: none;
    padding: 8px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: right;
    justify-content: space-between;
}

.spacer {
    flex-grow: 1;
}

.save-button:hover {
    background-color: #ff0101;
}

.save-button:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(255, 0, 0, 0.5);
}